import React, { useState, useEffect } from 'react'

import api from '../../services/api'

import * as S from './styled'

import Hero from '../../components/Hero'
import Pets from '../../components/Pets'

const Home = () => {
  const [slider, setSlider] = useState([])
  const [pets, setPets] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    api
      .get(`pages?slug=home`)
      .then(({ data }) => {
        const slider = data[0].acf.slider
        setSlider(slider)
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    api.get('posts/?per_page=12').then(({ data }) => {
      const pets = data
      setPets(pets)
    })
  }, [])

  return (
    <>
      <Hero slider={slider} />
      <Pets pets={pets} loading={isLoading} />
      {pets.length > 0 && (
        <S.PetsLink to="/adote">Ver todos os pets</S.PetsLink>
      )}
    </>
  )
}

export default Home
