import React from "react"

import * as S from "./styled"

const Breadcrumb = ({ petName }) => (
  <S.PetBreadcrumb aria-label="Breadcrumb">
    <S.PetBreadcrumbList>
      <S.PetBreadcrumbItem>
        <S.PetBreadcrumbLink to="/">Home</S.PetBreadcrumbLink>
      </S.PetBreadcrumbItem>
      <S.PetBreadcrumbItem>
        <S.PetBreadcrumbLink to="/adote">Adote</S.PetBreadcrumbLink>
      </S.PetBreadcrumbItem>
      <S.PetBreadcrumbItem>
        <span aria-current="page">{petName}</span>
      </S.PetBreadcrumbItem>
    </S.PetBreadcrumbList>
  </S.PetBreadcrumb>
)

export default Breadcrumb
