import styled from "styled-components"
import { NavLink } from "react-router-dom"

export const Navbar = styled.nav`
  position: relative;
  z-index: 1;
  background-color: var(--color-back-dark);
`

export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: var(--max-width);
  height: 70px;
  padding: 15px;
  margin: 0 auto;

  &.active {
    span {
      border-color: transparent;

      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
    ul {
      opacity: 1;
      visibility: visible;
    }
  }
`

export const NavHamburger = styled.span`
  display: none;
  position: absolute;
  width: 35px;
  height: 26px;
  right: 15px;
  border-top: 4px solid var(--color-prim);
  border-bottom: 4px solid var(--color-prim);
  transition: all 0.3s ease;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    top: calc(50% - 2px);
    background-color: var(--color-prim);
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
`

export const LogoLink = styled(NavLink)`
  display: inline-block;
  position: absolute;
  width: 86px;
  height: 86px;
  left: 12px;
  top: 6px;
  left: 5px;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 50, 100, 0.4);
  transition: all 0.3s cubic-bezier(0.75, -0.5, 0, 1.75);

  &:hover {
    box-shadow: 0 6px 18px 0 rgba(0, 50, 100, 0.2);
    transform: scale(1.1) rotate(-6deg);
  }
`

export const Logo = styled.img`
  display: block;
  max-width: 100%;
`

export const Menu = styled.ul`
  display: flex;
  padding: 0;
  margin: 0 0 0 auto;
  list-style: none;
  transition: all 0.3s ease;

  @media screen and (max-width: 767px) {
    display: block;
    position: absolute;
    width: 100%;
    padding: 15px 0;
    top: 70px;
    left: 0;

    opacity: 0;
    visibility: hidden;

    border-bottom: 2px solid #ccc;
    border-radius: 0 0 6px 6px;
    background-color: #ddd;

    li {
      margin: 25px 0;
      text-align: center;
    }
  }
`

export const MenuItem = styled.li`
  margin: 0 0 0 15px;
`

export const MenuLink = styled(NavLink)`
  display: block;
  position: relative;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 100%;
  text-decoration: none;
  color: var(--color-prim);

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -6px;
    left: 50%;
    opacity: 0.8;
    transform: translateX(-50%);
    border-radius: 1px;
    background-color: var(--color-prim);
    transition: all 0.3s cubic-bezier(0.75, -0.5, 0, 1.75);
  }

  &.active,
  &:hover {
    &:after {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    &:after {
      display: none;
    }
  }
`
