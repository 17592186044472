import styled from "styled-components"

export const CollaboratorsSection = styled.section`
  padding: 80px 0 60px;
  background-color: var(--color-text-light);
`

export const CollaboratorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: var(--max-width);
  padding: 0;
  margin: 0 auto;
`

export const CollaboratorsColumn = styled.div`
  width: calc(50% - 30px);
  padding: 0 15px;

  h1 {
    max-width: 300px;
    color: var(--color-back);
  }

  p {
    opacity: 0.5;
    color: var(--color-back);
  }

  @media screen and (max-width: 991px) {
    width: calc(100% - 15px);
  }
`

export const CollaboratorsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const CollaboratorsItem = styled.a`
  display: block;
  width: calc(20% - 15px);
  margin: 0 0 15px 15px;
  border-radius: var(--radius);
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.75, -0.5, 0, 1.75);

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 991px) {
    width: calc(20% - 30px);
    margin: 15px;
  }

  @media screen and (max-width: 767px) {
    width: calc(25% - 30px);
    margin: 15px;
  }

  @media screen and (max-width: 525px) {
    width: calc(33.333% - 30px);
    margin: 15px;
  }

  @media screen and (max-width: 374px) {
    width: calc(50% - 30px);
    margin: 15px;
  }
`

export const CollaboratorsImage = styled.img`
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
`
