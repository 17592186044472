import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  padding: 15px 15px 10px 15px;
  text-align: center;
  background-color: var(--color-text);

  a {
    display: inline-block;
    line-height: 100%;

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  svg {
    width: 20px;
    height: 20px;
    opacity: 0.3;
    transition: all 0.3s ease;
  }
`
