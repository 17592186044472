import React, { useState } from 'react'

import * as S from './styled'

const Donations = ({ donations }) => {
  const [customValue, setCustomValue] = useState('')

  function handleValue(event) {
    const value = event.target.value
    setCustomValue(
      `https://pagseguro.uol.com.br/v2/checkout/payment.html?receiverEmail=contatoupa@gmail.com&currency=BRL&itemId1=0001&itemDescription1=UPA%20Lorena&itemQuantity1=1&itemAmount1=${value}.00&encoding=utf-8`
    )
  }

  return (
    <S.DonationsSection>
      <S.DonationsWrapper>
        <h1>Faça uma doação</h1>
        <p>
          Ou saiba mais sobre outras formas de fazer uma doação. Clique aqui!
        </p>
      </S.DonationsWrapper>
      <S.DonationsItems>
        <S.DonationsWrapper className="columns">
          {donations.map((item, index) => (
            <S.DonationsItem key={index}>
              <S.DonationsItemPrice>
                <span className="coin">R$</span>
                {item.upa_donation_item}
                <span className="fraction">,00</span>
              </S.DonationsItemPrice>
              <S.DonationsItemApplication>
                {item.upa_donation_application}
              </S.DonationsItemApplication>
              <S.DonationsItemLink
                href={item.upa_donation_address}
                target="_blank"
                rel="nooppener nofollow"
              >
                Doar valor
              </S.DonationsItemLink>
            </S.DonationsItem>
          ))}
          {/* ------- */}
          <S.DonationsItem>
            <S.DonationsItemPrice>
              <span className="coin">R$</span>
              <input type="number" pattern="[0-9]*" onChange={handleValue} />
              <span className="fraction">,00</span>
            </S.DonationsItemPrice>
            <S.DonationsItemApplication>
              Digite e faça uma doação no valor que desejar
            </S.DonationsItemApplication>
            {customValue === '' ? (
              <S.DonationsItemLinkFake>Doar Valor</S.DonationsItemLinkFake>
            ) : (
              <S.DonationsItemLink
                href={customValue}
                target="_blank"
                rel="nooppener nofollow"
              >
                Doar valor
              </S.DonationsItemLink>
            )}
          </S.DonationsItem>
          {/* ------- */}
        </S.DonationsWrapper>
      </S.DonationsItems>
    </S.DonationsSection>
  )
}

export default Donations
