import styled from 'styled-components'
const background = require('../../assets/images/dog-watching.jpg')

export const VolunteersSection = styled.section`
  padding: 80px 0;
  background-color: var(--color-back-dark);
  background-image: url(${background.default});
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: 20% 100%;

  @media screen and (max-width: 991px) {
    background-size: 45%;
  }

  @media screen and (max-width: 585px) {
    background-size: 400px;
    background-position: bottom center;
  }
`

export const VolunteersWrapper = styled.div`
  display: flex;
  max-width: var(--max-width);
  padding: 0 15px;
  margin: 0 auto;
`

export const VolunteerContact = styled.div`
  padding: 15px 25px 15px;
  margin: 30px 0 0 0;
  border-radius: var(--radius);
  background-color: var(--color-back);
  border-bottom: 2px solid #d6c6cb;

  hr {
    margin: 10px 0 15px;
  }

  h3 {
    font-size: 1.45rem;
    font-weight: 700;

    span {
      display: block;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  p {
    margin-bottom: 5px;
  }
`

export const VolunteersContent = styled.div`
  max-width: 390px;
  margin-left: auto;

  @media screen and (max-width: 585px) {
    width: 100%;
    max-width: 100%;
    padding-bottom: 80px;
  }
`
