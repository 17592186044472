import React from 'react'

import * as S from './styled'

const Footer = () => (
  <S.FooterWrapper>
    <a href="https://paim.website" target="_blank" rel="noopener noreferrer">
      <svg
        data-v-4a9006aa=""
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1727 332 40.15 40.15"
      >
        <path
          data-v-4a9006aa=""
          fill="#ffffff"
          d="M23.66.016V0H0V11.471H5.736V40.15H17.207V33.434A17.208,17.208,0,1,0,23.66.016Zm-.717,22.927a5.736,5.736,0,1,1,5.736-5.736A5.736,5.736,0,0,1,22.943,22.943Z"
          transform="translate(-1727 332)"
        ></path>
      </svg>
    </a>
  </S.FooterWrapper>
)

export default Footer
