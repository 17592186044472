import React, { useEffect, useState } from 'react'

import api from '../../services/api'

import Pets from '../../components/Pets'

import * as S from './styled'

const Adopt = () => {
  const [pets, setPets] = useState({})
  const [page, setPage] = useState(1, 2)
  const [gender, setGender] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [buttonPrevStatus, setButtonPrevStatusDisabled] = useState(true)
  const [buttonNextStatus, setButtonNextStatusDisabled] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    api
      .get(`posts?per_page=${12}&page=${page}${gender}`)
      .then((response) => {
        const pets = response.data
        const totalPages = response.headers['x-wp-totalpages']
        setTotalPages(totalPages)
        setPets(pets)
      })
      .then(() => {
        totalPages < page + 1
          ? setButtonNextStatusDisabled(true)
          : setButtonNextStatusDisabled(false)

        page <= 1
          ? setButtonPrevStatusDisabled(true)
          : setButtonPrevStatusDisabled(false)
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false))
  }, [gender, page, totalPages])

  function handleNav(navDirection) {
    if (navDirection === 'next') {
      if (totalPages > page) {
        setPets({})
        setPage(page + 1)
        window.scrollTo(0, 0)
      }
    }

    if (navDirection === 'prev') {
      if (page >= 2) {
        setPets({})
        setPage(page - 1)
        window.scrollTo(0, 0)
      }
    }
  }

  function handleGender(event) {
    setPets({})
    if (event.target.value) {
      setGender(`&gender_tax=${event.target.value}`)
    } else {
      setGender('')
    }
  }

  return (
    <>
      <S.Filter>
        <S.FilterWrapper>
          <S.FilterColumn>
            <h1>
              Adote <span>um amigo!</span>
            </h1>
          </S.FilterColumn>
          <S.FilterColumn>
            <label htmlFor="gender">Filtre por gênero</label>
            <select name="gender" id="gender" onChange={handleGender}>
              <option value="">Todos</option>
              <option value="4">Fêmea</option>
              <option value="5">Macho</option>
            </select>
          </S.FilterColumn>
        </S.FilterWrapper>
      </S.Filter>
      <Pets pets={pets} loading={isLoading} />
      {totalPages > 1 && (
        <S.Pagination>
          <button onClick={() => handleNav('prev')} disabled={buttonPrevStatus}>
            <span>&lsaquo;</span> Anterior
          </button>
          <button onClick={() => handleNav('next')} disabled={buttonNextStatus}>
            Próximo <span>&rsaquo;</span>
          </button>
        </S.Pagination>
      )}
    </>
  )
}

export default Adopt
