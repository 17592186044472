import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const PetsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 230px;
  height: 50px;
  padding: 0 30px;
  margin: 30px auto 60px;
  border-radius: var(--radius);
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.045em;
  line-height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-back);
  background-color: var(--color-prim);
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--color-text);
  }
`
