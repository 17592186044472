import React, { useState, useEffect } from 'react'

import Donations from '../Donations'
import Volunteers from '../Volunteers'
import Collaborators from '../Collaborators'

import api from '../../services/api'

const Options = () => {
  const [options, setOptions] = useState()

  useEffect(() => {
    async function getOptions() {
      const { data } = await api.get(
        'https://upalorena.com.br/wp//wp-json/acf/v3/options/options'
      )
      try {
        setOptions(data)
      } catch (error) {
        console.log(error)
      }
    }

    getOptions()
  }, [])

  return (
    <>
      {options !== undefined && (
        <Donations donations={options.acf.upa_donations} />
      )}
      {options !== undefined && (
        <Volunteers volunteers={options.acf.upa_volunteers} />
      )}
      {options !== undefined && (
        <Collaborators
          about={options.acf.upa_colab_about}
          list={options.acf.upa_colab_list}
        />
      )}
    </>
  )
}

export default Options
