import React from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css'

import * as S from './styled'

const PetCard = ({ id, title, slug, gender_tax: gender, acf }) => {
  return (
    <S.Pet to={slug} id={`pet-${id}`}>
      <S.PetImageWrapper>
        <S.PetImage src={acf.pet_gallery[0].sizes.thumbnail} effect="blur" />
      </S.PetImageWrapper>
      <S.PetInfo>
        <S.PetName>
          <span>Nome</span>
          <p>{title.rendered}</p>
        </S.PetName>
        <S.PetGender>
          <span>Gênero</span>
          <p>{gender[0] === 4 ? 'Fêmea' : 'Macho'}</p>
        </S.PetGender>
        <S.PetAge>
          <span>Idade</span>
          <p>{acf.pet_age} anos</p>
        </S.PetAge>
      </S.PetInfo>
      <S.PetLink>Conhecer</S.PetLink>
    </S.Pet>
  )
}

export default PetCard
