import styled from 'styled-components'

import loading from '../../assets/images/loading.gif'

export const Slider = styled.section`
  position: relative;

  .tns-nav {
    position: absolute;
    width: 100%;
    max-width: var(--max-width);
    padding: 0 15px;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    z-index: 2;
  }

  button {
    width: 15px;
    height: 15px;
    margin: 0 8px 0 0;
    border: 1px solid var(--color-prim);
    border-radius: 50%;

    &.tns-nav-active {
      background-color: var(--color-prim);
    }
  }

  @media screen and (max-width: 767px) {
    .tns-nav {
      bottom: 30px;
      text-align: center;
    }
  }
`

export const SliderItem = styled.div`
  min-height: 560px;
  background-color: var(--color-back-dark);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.loading {
    background-image: url(${loading});
    background-size: 45px;
    background-position: center;
  }

  @media screen and (max-width: 767px) {
    background-position: 75% 50%;
  }
`

export const SliderWrapper = styled.div`
  position: relative;
  max-width: var(--max-width);
  min-height: 560px;
  padding: 0 15px;
  margin: 0 auto;
`

export const SliderContent = styled.div`
  position: absolute;
  max-width: 445px;
  bottom: 90px;

  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding: 30px 0 60px;
    left: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);

    h2 {
      padding: 0 15px;
      font-size: 3rem;
    }

    p {
      display: none;
    }
  }
`
