import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import Breadcrumb from '../../components/Breadcumbs'

import TinySlider from 'tiny-slider-react-wrapper'

import api from '../../services/api'

import * as S from './styled'

const Pet = () => {
  let { id } = useParams()
  const [pet, setPet] = useState()

  useEffect(() => {
    async function getPet() {
      const { data } = await api.get(`posts?slug=${id}`)
      try {
        setPet(data[0])
      } catch (error) {
        console.log(error)
      }
    }

    getPet()
  }, [id])

  const sliderSettings = {
    nav: true,
    controls: false,
    mouseDrag: true,
  }

  const gallery = pet?.acf?.pet_gallery

  return (
    <S.PetSection>
      <Breadcrumb petName={pet?.title?.rendered} />
      <S.PetWrapper>
        {pet !== undefined ? (
          <S.PetGallery>
            <TinySlider settings={sliderSettings}>
              {gallery.map((image, index) => (
                <LazyLoadImage
                  src={image.sizes.large}
                  key={index}
                  alt={`${pet.title.rendered} - Imagem ${index}`}
                  effect="blur"
                />
              ))}
            </TinySlider>
          </S.PetGallery>
        ) : (
          <S.PetGallery className="loading">
            <p>carregando imgens...</p>
          </S.PetGallery>
        )}
        <S.PetData>
          <span>Oi, eu sou</span>
          <h1>{pet?.title?.rendered}</h1>
          <S.PetInfo>
            <S.PetGender>
              <span>Gênero</span>
              <p>{pet?.gender_tax[0] === 4 ? 'Fêmea' : 'Macho'}</p>
            </S.PetGender>
            <S.PetAge>
              <span>Idade</span>
              <p>{pet?.acf?.pet_age ? pet?.acf?.pet_age + ' anos' : '---'}</p>
            </S.PetAge>
            <S.PetAbout>
              <span>Sobre</span>
              <p>{pet?.acf?.pet_detail ? pet?.acf?.pet_detail : '---'}</p>
            </S.PetAbout>
          </S.PetInfo>
          <S.PetInfo className="rows">
            <span>Informações</span>
            <div
              dangerouslySetInnerHTML={{
                __html: pet?.acf?.pet_about ? pet?.acf?.pet_about : '---',
              }}
            />
          </S.PetInfo>
        </S.PetData>
      </S.PetWrapper>
    </S.PetSection>
  )
}

export default Pet
