import React from 'react'

import PetCard from '../PetCard'

import * as S from './styled'

const Pets = ({ pets, loading }) => {
  return (
    <S.Pets>
      <S.PetsContent>
        {loading && (
          <p className="loading">
            <span>Carregando pets...</span>
          </p>
        )}
        {!loading && pets.length > 0
          ? pets.map((pet) => (
              <PetCard
                key={pet.id}
                id={pet.id}
                title={pet.title}
                slug={`/adote/${pet.slug}`}
                gender_tax={pet.gender_tax}
                acf={pet.acf}
              />
            ))
          : !loading && (
              <S.PetsEmpty>Nenhum pet cadastrado ainda...</S.PetsEmpty>
            )}
      </S.PetsContent>
    </S.Pets>
  )
}

export default Pets
