import { createGlobalStyle } from 'styled-components'

import loading from '../../assets/images/loading.gif'

import { variables } from './variables'
import { fonts } from '../fonts'

const GlobalStyle = createGlobalStyle`
  /* variables */
  ${variables}

  /* fonts */
  ${fonts}

  /* reset */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 160%;
    color: var(--color-text);
  }
  
  ol, ul {
    list-style: none;
  }
  
  blockquote, q {
    quotes: none;
  }
  
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *, input, select, textarea, option, button {
    outline: none!important;
  }

  * {
    box-sizing: border-box;
  }

  /* texts */
  h1 {
    margin: 0 0 15px;
    font-family: 'Amatic', sans-serif;
    font-size: 4rem;
    line-height: 100%;
    color: var(--color-prim);
  }
  
  h2 {
    margin: 0 0 15px;
    font-family: 'Amatic', sans-serif;
    font-size: 4rem;
    line-height: 100%;
    color: var(--color-prim);
  }

  p {
    margin: 0 0 20px;
    font-size: 1rem;
    line-height: 160%;

    &.loading {
      display: block;
      max-width: 320px;
      padding: 75px 30px 20px 30px;
      margin: 0 auto 45px;
      border-radius: var(--radius);
      font-size: 0.875rem;
      text-align: center;
      /* background-color: var(--color-back-dark); */
      background-image: url(${loading});
      background-size: 45px;
      background-position: center 15px;
      background-repeat: no-repeat;

      span {
        opacity: 0.3;
      }
    }
  }

  ul {
    padding: 0 0 0 20px;
    list-style: disc;
  }

  hr {
    height: 1px;
    margin: 55px 0 60px;
    border: 0;
    background-color: var(--color-back-dark);
  }

  a {
    color: var(--color-prim);

    &:hover {
      text-decoration: none;
    }
  }

  strong {
    font-weight: 700;
  }
`
export default GlobalStyle
