import styled from "styled-components"

export const DefaultWrapper = styled.div`
  max-width: var(--max-width);
  padding: 60px 15px 15px;
  margin: 0 auto;

  .wp-caption {
  }

  .wp-caption-text {
    max-width: calc(100% - 10px);
    padding: 5px;
    border-radius: 0 0 var(--radius) var(--radius);
    font-size: 0.75rem;
    text-align: center;
    background-color: var(--color-back-dark);
  }

  .alignleft {
    margin: 5px 15px 15px 0;
    float: left;
  }

  .video {
    position: relative;
    width: 100%;
    padding-bottom: 56.45%;
    margin-top: 45px;
    border: 1px solid var(--color-back-dark);
    border-radius: var(--radius);
    overflow: hidden;
    background-color: var(--color-back-dark);

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  h2 {
    margin: 45px 0 15px 0;
    font-size: 3rem;
    color: var(--color-text);
  }

  img {
    display: inline-block;
    border-radius: var(--radius) var(--radius) 0 0;
    border: 1px solid var(--color-back-dark);
    vertical-align: middle;
  }

  @media screen and (max-width: 620px) {
    .alignleft {
      margin: 0 auto 15px;
      float: inherit;
    }

    img {
      display: block;
    }
  }
`
