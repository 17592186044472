export const links = [
  // {
  //   label: 'Home',
  //   url: '/',
  // },
  {
    label: 'Adote',
    url: '/adote',
  },
  {
    label: 'Quem Somos',
    url: '/quem-somos',
  },
  // {
  //   label: 'Projetos',
  //   url: '/projetos',
  // },
  {
    label: 'Contato',
    url: '/contato',
  },
  {
    label: 'Quero Ajudar',
    url: '/quero-ajudar',
  },
]
