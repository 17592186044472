import React from 'react'
import TinySlider from 'tiny-slider-react-wrapper'

import * as S from './styled'

const sliderSettings = {
  nav: true,
  controls: false,
  mouseDrag: true,
}

const Hero = ({ slider }) => {
  return (
    <S.Slider>
      {slider.length > 0 ? (
        <TinySlider settings={sliderSettings}>
          {slider.map((slide, index) => (
            <S.SliderItem
              key={index}
              style={{
                backgroundImage: `url(${slide.slider_image.sizes.slider})`,
              }}
            >
              <S.SliderWrapper>
                <S.SliderContent>
                  <h2>{slide.slider_title}</h2>
                  <p>{slide.slider_about}</p>
                </S.SliderContent>
              </S.SliderWrapper>
            </S.SliderItem>
          ))}
        </TinySlider>
      ) : (
        <S.SliderItem className="loading" />
      )}
    </S.Slider>
  )
}

export default Hero
