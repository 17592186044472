import styled from 'styled-components'
import loading from '../../assets/images/loading.gif'

export const PetSection = styled.section`
  max-width: var(--max-width);
  padding: 0 15px 60px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    padding: 0 15px 45px;
  }
`

export const PetWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 520px) {
    display: block;
  }
`

export const PetGallery = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 45%;
  min-height: 320px;
  border: 1px solid var(--color-back-dark);
  border-radius: var(--radius);
  overflow: hidden;
  background-color: var(--color-back-dark);
  background-image: url(${loading});
  background-size: 45px;
  background-position: center;
  background-repeat: no-repeat;

  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    vertical-align: middle;
    border-radius: var(--radius);
  }

  p {
    position: relative;
    margin: 0;
    top: 40px;
    opacity: 0.5;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .tns-nav {
    position: absolute;
    width: 100%;
    padding: 0 15px;
    bottom: 10px;
    z-index: 2;
  }

  button {
    width: 15px;
    height: 15px;
    margin: 0 8px 0 0;
    border: 1px solid var(--color-prim);
    border-radius: 50%;

    &.tns-nav-active {
      background-color: var(--color-prim);
    }
  }

  @media screen and (max-width: 767px) {
    align-items: inherit;
    min-width: 50%;
    max-height: inherit;
    min-height: inherit;
    background-position: center 60px;
  }

  @media screen and (max-width: 520px) {
    min-width: inherit;
    max-height: inherit;
    min-height: inherit;
    width: 100%;
    border-radius: var(--radius) var(--radius) 0 0;
  }
`

export const PetData = styled.div`
  position: relative;
  width: 55%;
  padding: 0 0 0 30px;

  h1 {
    position: relative;
    font-size: 7.45rem;
    margin: 0 0 45px -3px;

    &:after {
      content: '';
      position: absolute;
      width: 45px;
      height: 2px;
      left: 0;
      bottom: -20px;
      background-color: var(--color-comp);
    }
  }

  span {
    display: block;
    margin: 0 0 10px;
    opacity: 0.5;
  }

  @media screen and (max-width: 767px) {
    padding: 0 0 0 30px;

    h1 {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 520px) {
    padding: 15px 0 0 0;
  }

  @media screen and (max-width: 374px) {
    padding: 30px 0 0;
  }
`

export const PetInfo = styled.div`
  display: flex;
  margin-bottom: 20px;

  span {
    margin: 0;
    opacity: 0.5;
    font-size: 0.845rem;
  }

  p {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
  }

  &.rows {
    display: block;
  }

  @media screen and (max-width: 767px) {
    display: block;
    margin-bottom: 0;

    > div {
      width: 100%;
      padding: 0 0 15px 0;
    }
  }
`

export const PetAbout = styled.div`
  width: 60%;
  padding: 5px 0 5px 5px;
`

export const PetGender = styled.div`
  width: 20%;
  padding: 5px 5px 0 0;
`

export const PetAge = styled.div`
  min-width: 20%;
  padding: 5px;
`
