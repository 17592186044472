import { css } from 'styled-components'

import amatic from './amatic.woff'
import josefin from './josefin.woff'

export const fonts = css`
  @font-face {
    font-family: 'Amatic';
    src: url(${amatic}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Josefin';
    src: url(${josefin}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
`
