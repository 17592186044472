import React, { useRef } from 'react'

import * as S from './styled'

import { links } from './content'

const logo = require('../../assets/images/logo-upa-lorena.png')

const Header = () => {
  const navBar = useRef(null)

  function handleNav() {
    const nav = navBar.current
    nav.classList.toggle('active')
  }

  return (
    <S.Navbar>
      <S.NavbarWrapper ref={navBar}>
        <S.LogoLink to="/">
          <S.Logo src={logo.default} alt="UPA Lorena" />
        </S.LogoLink>
        <S.Menu>
          {links.map((link, index) => (
            <S.MenuItem key={index}>
              <S.MenuLink
                to={link.url}
                activeClassName="active"
                onClick={handleNav}
              >
                {link.label}
              </S.MenuLink>
            </S.MenuItem>
          ))}
        </S.Menu>
        <S.NavHamburger onClick={handleNav} />
      </S.NavbarWrapper>
    </S.Navbar>
  )
}

export default Header
