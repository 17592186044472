import React from "react"

import * as S from "./styled"

const Collaborators = ({ about, list }) => (
  <S.CollaboratorsSection>
    <S.CollaboratorsWrapper>
      <S.CollaboratorsColumn>
        <h1>Empresas que colaboram</h1>
        <div dangerouslySetInnerHTML={{ __html: about }} />
      </S.CollaboratorsColumn>
      <S.CollaboratorsColumn>
        <S.CollaboratorsList>
          {list.map((item, index) => (
            <S.CollaboratorsItem
              href={item.upa_colab_list_item_address}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <S.CollaboratorsImage
                src={item.upa_colab_list_item.sizes.thumbnail}
                alt={item.upa_colab_list_item.title}
              />
            </S.CollaboratorsItem>
          ))}
        </S.CollaboratorsList>
      </S.CollaboratorsColumn>
    </S.CollaboratorsWrapper>
  </S.CollaboratorsSection>
)

export default Collaborators
