import styled from 'styled-components'

export const DonationsSection = styled.section`
  padding: 60px 0 0 0;
`

export const DonationsWrapper = styled.div`
  width: 100%;
  max-width: var(--max-width);
  padding: 0 15px;
  margin: 0 auto;

  &.columns {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
`

export const DonationsItems = styled.div`
  display: flex;
  background-color: var(--color-prim);
`

export const DonationsItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 60px 15px;

  input {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    max-width: 160px;
    height: 60px;
    padding-top: 5px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: var(--radius);
    overflow: hidden;
    font-family: 'Josefin', sans-serif;
    font-size: 4rem;
    line-height: 100%;
    text-align: center;
    color: var(--color-back);
    background-color: transparent;
    transition: all 0.3s ease;

    &:focus {
      border-color: var(--color-back);
    }
  }

  @media screen and (max-width: 878px) {
    width: 50%;

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px solid rgba(0, 50, 100, 0.1);
    }
  }

  @media screen and (max-width: 374px) {
    width: 100%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-bottom: 1px solid rgba(0, 50, 100, 0.1);
    }
  }
`

export const DonationsItemPrice = styled.h2`
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-family: 'Josefin', sans-serif;
  line-height: 100%;
  color: var(--color-back);

  .coin {
    margin: 0 5px 0 0;
    font-size: 1rem;
  }

  .fraction {
    margin: 0 0 0 5px;
    font-size: 1rem;
  }

  &:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 1px;
    bottom: 0;
    left: calc(50% - 15px);
    opacity: 0.2;
    background-color: var(--color-back);
  }
`
export const DonationsItemApplication = styled.p`
  max-width: 200px;
  margin: 0 auto 30px;
  font-size: 0.875rem;
  text-align: center;
  opacity: 0.6;
  color: var(--color-back);
`

export const DonationsItemLink = styled.a`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 180px;
  padding: 15px;
  margin: 0 auto;
  border: 2px solid var(--color-comp);
  border-radius: var(--radius);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-comp);
  transition: all 0.3s ease;
  user-select: none;

  &:before {
    content: '';
    position: absolute;
    top: -3px;
    left: calc(50% - 3px);
    opacity: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid var(--color-comp);
    transition: all 0.1s ease;
  }

  &:hover {
    color: var(--color-prim);
    background-color: var(--color-comp);

    &:before {
      opacity: 1;
      top: -6px;
    }
  }
`

export const DonationsItemLinkFake = styled.span`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 180px;
  padding: 15px;
  margin: 0 auto;
  border: 2px solid var(--color-comp);
  border-radius: var(--radius);
  opacity: 0.5;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-comp);
  user-select: none;
  cursor: default;
`
