import styled from "styled-components";
const background = require("../../assets/images/bg-filter.jpg");

export const Filter = styled.section`
  padding: 45px 0;
  background-image: url(${background.default});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 991px) {
    background-position: 65% center;
  }

  @media screen and (max-width: 440px) {
    background-position: 100% center;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  max-width: var(--max-width);
  padding: 0 15px;
  margin: 0 auto;

  @media screen and (max-width: 440px) {
    display: block;
  }
`;

export const FilterColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 220px;
  margin: 0 30px 0 0;

  @media screen and (max-width: 440px) {
    max-width: 100%;
  }

  h1 {
    margin: 0;
    line-height: 100%;
    color: var(--color-back);

    span {
      display: block;
    }

    @media screen and (max-width: 440px) {
      margin-bottom: 15px;
    }
  }

  label {
    margin: 0 0 5px;
    font-size: 0.875rem;
    line-height: 100%;
    color: var(--color-back);
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;

    width: 100%;
    max-width: 160px;
    height: 45px;
    padding: 0 15px;
    margin: 0 0 10px;
    border: 0;
    border-radius: var(--radius);
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-prim);
    background-color: var(--color-back);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjY5NC42NjcgNTk1IDE1Ljc2OSAxMC4yMTQiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMwMDdkYzc7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNy44ODUsMTAuMTg2bC0uMDI4LjAyOEwwLDIuMzU3LDIuMzU3LDAsNy44ODUsNS41MjgsMTMuNDEyLDBsMi4zNTcsMi4zNTdMNy45MTMsMTAuMjE0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjk0LjY2NyA1OTUpIi8+PC9zdmc+");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
  }
`;

export const PetsWrapper = styled.section`
  /* max-width: var(--max-width);
  margin: 0 auto; */
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px auto;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px 5px;
    margin: 0 10px;
    border: 0;
    border-radius: var(--radius);
    font-size: 0.675rem;
    font-weight: 700;
    letter-spacing: 0.045em;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--color-prim);
    background-color: var(--color-back);
    transition: all 0.3s ease;
    cursor: pointer;

    span {
      display: inline-block;
      margin: 0 10px 4px 10px;
      font-size: 1.275rem;
      font-weight: 400;
      line-height: 100%;
      transition: all 0.3s ease;
    }

    &:nth-child(1) {
      padding-left: 10px;
    }

    &:nth-child(2) {
      padding-right: 10px;
    }

    &:hover {
      border-color: var(--color-prim);
      background-color: var(--color-comp);

      &:nth-child(1) {
        span {
          transform: translateX(-5px);
        }
      }

      &:nth-child(2) {
        span {
          transform: translateX(5px);
        }
      }
    }

    &:disabled {
      color: #bbb;
      background-color: var(--color-back-dark);
      cursor: default;

      &:hover {
        &:nth-child(1) {
          span {
            transform: translateX(0);
          }
        }

        &:nth-child(2) {
          span {
            transform: translateX(0);
          }
        }
      }
    }
  }
`;
