import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'
import Options from './components/Options'

// pages
import Home from './pages/Home'
import Adopt from './pages/Adopt'
import Pets from './pages/Pets'
import Page from './pages/Default'

import ScrollToTop from './utils/scrollToTop'
import GlobalStyle from './assets/styles/globalStyle'

const App = () => (
  <Router>
    <ScrollToTop />
    <GlobalStyle />
    <Header />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/adote" exact component={Adopt} />
      <Route path="/:id" exact component={Page} />
      <Route path="/adote/:id" exact component={Pets} />
    </Switch>
    <Options />
    <Footer />
  </Router>
)

export default App
