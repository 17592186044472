import React from 'react'

import * as S from './styled'

const Volunteers = ({ volunteers }) => (
  <S.VolunteersSection>
    <S.VolunteersWrapper>
      <S.VolunteersContent>
        <h1>É um voluntáro vindo ali?</h1>
        <div dangerouslySetInnerHTML={{ __html: volunteers }} />
        <S.VolunteerContact>
          <h3>
            <span>Quer ser um voluntário?</span> Entre em contato!
          </h3>
          <hr />
          <p>
            Entre em contato pelo direct de nosso instagram: 
            <a href="https://www.instagram.com/upalorena" rel="noreferrer" target='_blank'>
              @upalorena
            </a>
          </p>
        </S.VolunteerContact>
      </S.VolunteersContent>
    </S.VolunteersWrapper>
  </S.VolunteersSection>
)

export default Volunteers
