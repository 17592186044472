import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const Pet = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(25% - 30px);
  max-height: 330px;
  margin: 15px;
  border-radius: var(--radius);
  overflow: hidden;
  text-decoration: none;
  color: var(--color-text);
  background-color: var(--color-back-dark);

  span {
    display: block;
    width: 100%;
    margin: 0%;
    padding: 0;
    line-height: 100%;
  }

  img {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    line-height: 100%;
    transition: all 0.3s ease !important;
  }

  &:hover {
    img {
      transform: scale(1.15);
    }

    div {
      opacity: 1;
    }
  }

  @media screen and (max-width: 991px) {
    width: calc(33.333% - 30px);

    div {
      opacity: 1;
    }
  }

  @media screen and (max-width: 585px) {
    width: calc(50% - 30px);
    max-height: 440px;
  }

  @media screen and (max-width: 374px) {
    width: calc(100%);
  }
`

export const PetImageWrapper = styled.div`
  overflow: hidden;
`

export const PetImage = styled(LazyLoadImage)`
  display: block;
  position: relative;
  width: 100%;
  transform: scale(1.05);
  transition: all 0.3s cubic-bezier(0.75, -0.5, 0, 1.75);
`

export const PetInfo = styled.div`
  display: flex;
  padding: 12px 15px 10px 15px;

  span {
    opacity: 0.5;
    font-size: 0.745rem;
  }

  p {
    max-height: 22px;
    margin: 0;
    overflow: hidden;
    font-size: 0.875rem;
    font-weight: bold;
  }
`

export const PetName = styled.div`
  width: 100%;
  padding: 5px 5px 5px 0;
`

export const PetGender = styled.div`
  width: 65px;
  padding: 5px;
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: right;
  }
`

export const PetAge = styled.div`
  min-width: 65px;
  padding: 5px 0 5px 5px;
  text-align: right;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

export const PetLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  left: 0;
  bottom: 0;
  opacity: 0.6;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: var(--color-prim);
  background-color: var(--color-comp);
`
