import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import api from '../../services/api'

import * as S from './styled'

const Page = () => {
  let { id } = useParams()
  const [page, setPage] = useState({})

  useEffect(() => {
    setPage({})

    api
      .get(`pages?slug=${id}`)
      .then(({ data }) => {
        setPage(data[0])
      })
      .catch((error) => console.log(error))
  }, [id])

  return (
    <>
      <S.DefaultWrapper>
        {page?.id !== undefined ? (
          <>
            <h1>{page?.title?.rendered}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: page?.content?.rendered }}
            />
          </>
        ) : (
          <p className="loading">
            <span>Carregando conteúdo...</span>
          </p>
        )}
      </S.DefaultWrapper>
    </>
  )
}

export default Page
