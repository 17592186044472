import styled from "styled-components"
import { Link } from "react-router-dom"

export const PetBreadcrumb = styled.nav`
  margin: 30px 0;
`

export const PetBreadcrumbList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
`

export const PetBreadcrumbItem = styled.li`
  margin: 0 5px 0 0;
  font-size: 0.75rem;
  letter-spacing: 0.0875em;
  line-height: 100%;

  &:not(:last-child) {
    &:after {
      content: "/";
      margin: 0 0 0 6px;
      color: var(--color-back-darker);
    }
  }

  span {
    color: var(--color-back-darker);
  }
`

export const PetBreadcrumbLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
